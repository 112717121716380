<template>
  <div class="draft-screen-review">
    <j-form model="drafts.draft" hide-errors>

      <!-- Title -->
      <j-control name="title" />

      <!-- Director -->
      <j-control name="director" />

      <!-- Starring -->
      <j-control name="cast" />

      <!-- Studio -->
      <j-control name="studio" />
    </j-form>

    <!-- Article Body -->
    <draft-article-body :draft="draft" />
  </div>
</template>

<script>

import DraftArticleBody from './DraftArticleBody'

export default {
  name: 'DraftScreenReview',
  components: {
    DraftArticleBody
  },
  props: ['draft']
}

</script>
